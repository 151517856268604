import React from "react";
import useTheme from "../hooks/useTheme";

export const themeContext = React.createContext();
const { Provider } = themeContext;

export default function ThemeContext(props) {
  const { theme, toggleTheme } = useTheme();

  return (
    <Provider value={{ theme, toggleTheme }}>
      {props.children}
    </Provider>
  )
}