import { Space } from "components/space/Space";
import { globalContext } from "context/GlobalContext";
import { themeContext } from "context/ThemeContext";
import { useContext } from "react";
import { HourWindStyled } from "./styled";
import WindBar from "./WindBar";

export default function HourWind() {
  const { theme } = useContext(themeContext);
  const { data } = useContext(globalContext);

  if (!data) return <></>

  const [min, max] = data.forecast.forecastday[0].hour.reduce((acc, value) => {
    const min = Math.min(acc[0], value.wind_kph);
    const max = Math.max(acc[1], value.wind_kph);
    return [min, max];
  }, [999999, -1]);

  return (
    <HourWindStyled theme={theme}>
      <h3>Wind:</h3>
      <section>
        {data.forecast.forecastday[0].hour.map(hour => {
          const time = hour.time.split(' ')[1];
          const wind = Math.round(hour.wind_kph);
          return (
            <div key={hour.time_epoch}>
              <label>{time}</label>
              <Space vertical size='small' />
              <WindBar min={min} max={max} value={wind} />
              <Space vertical size='small' />
              <span>{wind}</span>
            </div>
          )
        })}
      </section>
    </HourWindStyled>
  )
}