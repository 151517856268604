import styled from "styled-components";

export const ThemeSwitchStyled = styled.div`
  display: flex;
  padding: 16px;
  align-self: flex-end;
`;

export const TextIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  /* padding */
`;