import './App.css';
import { themeContext } from './context/ThemeContext';
import { useContext } from 'react';
import GlobalContext from './context/GlobalContext';
import Wrapper from './screens/wrapper/Wrapper';
import { MobileLayout } from 'components/layout/MobileLayout';

function App() {
  const { theme } = useContext(themeContext);

  return (
    <MobileLayout theme={theme}>
      <GlobalContext>
        <Wrapper />
      </GlobalContext>
    </MobileLayout>
  );
}

export default App;
