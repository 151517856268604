import Content from "components/content/Content";
import InputSearch from "components/search/InputSearch";
import ThemeSwitch from "components/themeswitch/ThemeSwitch";
import { globalContext } from "context/GlobalContext";
import { useContext, useEffect } from "react";
import { WrapperStyled, ContentStyled } from "./styled";

export default function Wrapper() {

  const { loading, fetchCurrent } = useContext(globalContext)

  useEffect(() => {
    const doFetchCurrent = () => {
      fetchCurrent();
    }
    doFetchCurrent();
  }, []);

  return (
    <WrapperStyled>
      <ThemeSwitch />
      <InputSearch disabled={loading} />
      <ContentStyled>
        <Content />
      </ContentStyled>
    </WrapperStyled>
  )
}