import styled from "styled-components";

const ICON_SIZE = {
  'small': 20,
  'medium': 24,
  'normal': 36,
  'large': 56,
  'extraLarge': 100,
}

export const WeatherIconStyled = styled.div`
  width: ${props => ICON_SIZE[props.size]}px;
  height: ${props => ICON_SIZE[props.size]}px;
  & img{
    width: ${props => ICON_SIZE[props.size]}px;
    height: ${props => ICON_SIZE[props.size]}px;
  }
`;