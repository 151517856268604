import { windColors } from "constants/theme";
import React from "react";
import { WindBarStyled } from "./styled"

function WindBar({ max, value }) {

  if (!value) return <></>

  let height = value;
  if (max > 33) {
    height = (33 * value) / max;
  }
  height += 3;
  if (height > 36) height = 36;
  if (height < 3) height = 3;

  const color = windColors.reduce((acc, [v, c]) => {
    if (value > v) acc = c;
    return acc;
  }, windColors[0][1]);



  return (
    <WindBarStyled height={height} color={color}>
      <div></div>
    </WindBarStyled>
  )
}

export default React.memo(WindBar);