import styled from "styled-components";

export const InputSearchStyled = styled.div`
  /* margin-top: 16px; */
  & input{
    border-radius: 9999px;
    border: none;
    outline: none;
    color: ${props => props.theme.textColor};
    border-bottom: 2px solid ${props => props.theme.colors.textSecondary};
    padding: 10px 16px 8px 16px;
    background-color: ${props => props.theme.colors.bgSecondary};
    text-align: center;
    font-size: 1.1em;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${props => props.theme.colors.textSecondary};
      opacity: 1; /* Firefox */
    };
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${props => props.theme.colors.textSecondary};
    };
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: ${props => props.theme.colors.textSecondary};
    };
  }
`;