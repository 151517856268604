import styled from "styled-components";

export const HourWindStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  width: 85%;
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  border-radius: 16px;
  user-select: none;

  & h3{
    padding: 0;
    margin-top: 0;
  }
  

  & section{
    display: flex;
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 8px;
  };

  & section > div{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
  };

  & label{
    font-size: 0.9em;
    color: ${props => props.theme.colors.textSecondary};
  };

  & span{
    font-size: 1.1em;
  };

  /* Tamaño del scroll */
  & section::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Estilos barra (thumb) de scroll */
  & section::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgSecondary};
    border-radius: 999px;
  }

  /* Estilos track de scroll */
  & section::-webkit-scrollbar-track {
    background: ${props => props.theme.backgroundColor};
    border-radius: 999px;
  }

  /* & section::-webkit-scrollbar-track:hover,  */
  & section::-webkit-scrollbar-track:active {
    background: ${props => props.theme.backgroundColor};
  }
`;


export const WindBarStyled = styled.section`
  display: flex;
  align-items: flex-end;
  width: 24px;
  height: 36px;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  & div {
    padding: 0 !important;
    margin: 0 !important;
    width: 24px;
    /* height: 36px; */
    height: ${props => props.height}px;
    background-color: ${props => props.color};
  }
`;