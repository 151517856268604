import { MobileBackgroundStyled, MobileLayoutStyled } from './styled';


export function MobileLayout({ children, theme }) {

  return (
    <MobileBackgroundStyled>
      <MobileLayoutStyled theme={theme}>
        {children}
      </MobileLayoutStyled>
    </MobileBackgroundStyled>
  )
}

