import React from "react";
import { WeatherIconStyled } from "./styled";

const imagesPath = process.env.PUBLIC_URL + "/images/";
function WeatherIcon({ is_day = true, code = 1003, size = 'normal' }) {

  const path = `${imagesPath}${(is_day && 'day') || 'night'}/${code}.svg`;

  return (
    <WeatherIconStyled size={size} >
      <img src={path} alt={code} />
    </WeatherIconStyled>
  )
}

export default React.memo(WeatherIcon);