import Current from "components/current/Current"
import { Error } from "components/error/Error"
import HourTemperature from "components/hour/HourTemperature"
import { Loading } from "components/loading/Loading"
import { Space } from "components/space/Space"
import HourWind from "components/wind/HourWind"
import { globalContext } from "context/GlobalContext"
import { useContext } from "react"

export default function Content() {

  const { loading, error, data } = useContext(globalContext)

  if (loading)
    return <Loading />
  if (error)
    return <Error message={error} />

  if (!data) return <></>

  return (
    <>
      <h2>{data.location.name}</h2>
      <Space vertical size='normal' />
      <Current data={data} />
      <Space vertical size={'large'} />
      <HourTemperature />
      <Space vertical size={'large'} />
      <HourWind />
    </>
  )
}