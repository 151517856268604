import styled from "styled-components";

export const CurrentStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 16px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  border-radius: 16px;
`;

export const CurrentInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CurrentNameStyled = styled.label`
  font-size: 1.2em;
`;

export const CurrentTemperatureStyled = styled.div`
  align-self: center;
  & span{
    font-size: 3em;
    font-weight: bold;
    margin: 0 8px;
  };
  & label{
    color: ${props => props.theme.colors.textSecondary};
    font-size: 1.5em;
  };
`;

export const CurrentFeelsLikeStyled = styled.label`
  font-size: 0.7em;
  color: ${props => props.theme.colors.textSecondary};
  align-self: center;
`;

