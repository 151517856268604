import { Space } from "components/space/Space";
import WeatherIcon from "components/weatherIcon/WeatherIcon";
import { themeContext } from "context/ThemeContext";
import { useContext } from "react";
import { CurrentFeelsLikeStyled, CurrentInfoStyled, CurrentNameStyled, CurrentStyled, CurrentTemperatureStyled } from "./styled";

export default function Current({ data }) {
  const { theme } = useContext(themeContext);

  if (!data) return <></>
  return (
    <CurrentStyled>
      <WeatherIcon size={'extraLarge'} is_day={data.current.is_day} code={data.current.condition.code} />
      <Space horizontal size='normal' />
      <CurrentInfoStyled>
        <CurrentNameStyled>
          {/* {data.location.name} */}
          {data.current.condition.text}
        </CurrentNameStyled>
        <CurrentTemperatureStyled theme={theme}>
          <span>{`${data.current.temp_c}`}</span>
          <label>°C</label>
        </CurrentTemperatureStyled>
        <CurrentFeelsLikeStyled theme={theme}>
          Feels like: {Math.round(data.current.feelslike_c)}°
        </CurrentFeelsLikeStyled>
      </CurrentInfoStyled>

    </CurrentStyled>
  )
}