import styled from 'styled-components';

export const MobileBackgroundStyled = styled.div`
  display: grid;
  height: 100vh;
  /* width: 100%; */
  place-items: center;
  background-image: radial-gradient(#D4EAEC 3%, #F1F5F8 8%);
  background-position: 0 0 25px 25px;
  background-size: 50px 50px;
  overflow: hidden;
`;

export const MobileLayoutStyled = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  border-radius: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  /* position: relative; */
  @media screen and (min-width: 500px){
    height: 90vh;
    width: 500px;
    max-width: 500px;
    border-radius: 10px;
  }
`;