import { themeContext } from "context/ThemeContext";
import { useContext, useEffect, useState } from "react";
import { TextIconStyled, ThemeSwitchStyled } from "./styled";
import Switch from 'react-switch';


export default function ThemeSwitch() {
  const { theme, toggleTheme } = useContext(themeContext);
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(theme.dark);
  }, [theme])

  const handleChangeSwitch = (checked) => {
    toggleTheme();
  }

  return (
    <ThemeSwitchStyled>
      <Switch checked={checked} onChange={handleChangeSwitch}
        onColor='#50535E'
        offColor='#50535E'
        offHandleColor='#eee'
        onHandleColor='#eee'
        height={28}
        handleDiameter={23}
        checkedIcon={
          <TextIconStyled theme={theme}>🌜</TextIconStyled>
        }
        uncheckedIcon={
          <TextIconStyled theme={theme}>🌞</TextIconStyled>
        }
      />


    </ThemeSwitchStyled>
  )
}