import { useReducer } from "react";
import { fetchForecast } from "services/weatherApi";

const initialState = {
  data: null,
  loading: false,
  error: null
}

const ACTIONS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
}

const reducer = (state, action) => {

  switch (action.type) {
    case ACTIONS.LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      }
    default:
      return state;
  }

}

export default function useWeather() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchByPlace = async (query) => {
    dispatch({ type: ACTIONS.LOADING });
    try {
      const { data } = await fetchForecast(query)
      dispatch({ type: ACTIONS.SUCCESS, payload: data });
    } catch (err) {
      console.log("Error: ", err)
      dispatch({ type: ACTIONS.ERROR, payload: "Sorry! Unable to find the location." });
    }
  };

  const fetchCurrent = async () => {
    dispatch({ type: ACTIONS.LOADING });

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;
      const query = `${lat},${lon}`;
      const { data } = await fetchForecast(query)
      dispatch({ type: ACTIONS.SUCCESS, payload: data });
    } catch (err) {
      console.log("Error: ", err)
      dispatch({ type: ACTIONS.ERROR, payload: "Sorry! the current location is unreachable." });
    }
  };

  const { loading, data, error } = state;

  return { loading, data, error, fetchCurrent, fetchByPlace };
}