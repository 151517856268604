const colors = {
  light: {
    primary: '#3f51b5',
    secondary: '#283593',
    accent: '#ff5722',
    bgSecondary: '#DCDCEA',
    textSecondary: '#707080',
  },
  dark: {
    primary: '#3f51b5',
    secondary: '#283593',
    accent: '#ff5722',
    bgSecondary: '#67677B',
    textSecondary: '#AEAEBA',
  }
}

export const lightTheme = {
  dark: false,
  backgroundColor: '#fff',
  textColor: '#1C1E21',
  colors: colors.light,
}

export const darkTheme = {
  dark: true,
  backgroundColor: '#20232A',
  textColor: '#F5F6F7',
  colors: colors.dark,
}

export const windColors = [
  [-99999, '#029BE4'],
  [20, '#25A699'],
  [30, '#0E9C57'],
  [40, '#679F37'],
  [50, '#F79500'],
  [60, '#C63300'],
  [80, '#693F85'],
]