import Lottie from "react-lottie";
import error from './error.json';
import { ErrorStyled } from "./styled";

export function Error({ message }) {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: error,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <ErrorStyled>
      <Lottie
        options={defaultOptions}
        width={120}
        height={120}
      />
      {/* <Space vertical size='normal' /> */}
      <label>{message}</label>
    </ErrorStyled>

  )
}