import React from "react";
import useWeather from "../hooks/useWeather";

export const globalContext = React.createContext();
const { Provider } = globalContext;

export default function GlobalContext(props) {
  const weatherState = useWeather();

  return (
    <Provider value={weatherState}>
      {props.children}
    </Provider>
  )
}