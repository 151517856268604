import Lottie from "react-lottie";
import loadingCloud from './loading-cloud.json';

export function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCloud,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return <Lottie
    options={defaultOptions}
    width={200}
    height={200}
  />

}