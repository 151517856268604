import { Space } from "components/space/Space";
import WeatherIcon from "components/weatherIcon/WeatherIcon";
import { globalContext } from "context/GlobalContext";
import { themeContext } from "context/ThemeContext";
import { useContext } from "react";
import { HourTemperatureStyled } from "./styled";

export default function HourTemperature() {
  const { theme } = useContext(themeContext);
  const { data } = useContext(globalContext);

  if (!data) return <></>

  return (
    <HourTemperatureStyled theme={theme}>
      <h3>Temperature:</h3>
      <section>
        {data.forecast.forecastday[0].hour.map(hour => {
          const time = hour.time.split(' ')[1];
          const temp = Math.round(hour.temp_c);
          return (
            <div key={hour.time_epoch}>
              <label>{time}</label>
              <Space vertical size='small' />
              <WeatherIcon size='medium' is_day={hour.is_day} code={hour.condition.code} />
              <Space vertical size='small' />
              <span>{temp}°</span>
            </div>
          )
        })}
      </section>
    </HourTemperatureStyled>
  )

}