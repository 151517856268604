import axios from 'axios';
import { WEATHER_API_KEY, WEATHER_API_URL } from 'constants/api';

const createUrl = ({ lang = 'en', days = 7, query = 'sidney' }) => {
  const keyParam = `key=${WEATHER_API_KEY}&`;
  const langParam = lang ? `lang=${lang}&` : '';
  const daysParam = days ? `days=${days}&` : '';
  const queryParam = query ? `q=${query}` : 'q=london';
  return `${WEATHER_API_URL}?${keyParam}${langParam}${daysParam}${queryParam}`;
}

export const fetchForecast = async (query) => {
  const url = createUrl({ query });
  return axios.get(url);
}
