import styled from "styled-components";

const DIMENS = {
  'small': '8px',
  'medium': '12px',
  'normal': '16px',
  'large': '24px',
}

const getHeight = ({ vertical, size }) => {
  return vertical ? DIMENS[size] : 0;
}
const getWidth = ({ horizontal, size }) => {
  return horizontal ? DIMENS[size] : 0;
}

export const Space = styled.div`
  height: ${getHeight};
  width: ${getWidth};
`;