import styled from "styled-components";

export const ErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & label {
    font-size: 1.1.em;
  }
`;