import { globalContext } from "context/GlobalContext";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { themeContext } from "../../context/ThemeContext";
import { InputSearchStyled } from "./styled";

function InputSearch(props) {
  const { theme } = useContext(themeContext)
  const { fetchByPlace } = useContext(globalContext);
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    fetchByPlace(data.query);
  }

  return <InputSearchStyled theme={theme}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        placeholder="Search for a city..."
        name="query" {...props}
        ref={register({ required: true, minLength: 2 })}
      />
    </form>
  </InputSearchStyled >
}

export default React.memo(InputSearch);