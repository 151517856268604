import { useCallback } from "react";
import { useReducer } from "react";
import { darkTheme, lightTheme } from "../constants/theme";

const ACTIONS = {
  TOGGLE_THEME: "TOGGLE_THEME",
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_THEME:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}

export default function useTheme() {
  const [state, dispatch] = useReducer(reducer, darkTheme)

  const toggleTheme = useCallback(() => {
    if (state.dark) {
      dispatch({ type: ACTIONS.TOGGLE_THEME, payload: lightTheme });
    } else {
      dispatch({ type: ACTIONS.TOGGLE_THEME, payload: darkTheme });
    }
  }, [state.dark]);


  return { theme: state, toggleTheme };
}